import React from 'react';
import {
  Routes,
  Route
} from 'react-router-dom';

import IndexPage from './pages/Index.js';
import PublishingPage from './pages/Publishing.js';
import AcademicPublishingPage from './pages/AcademicPublishing.js';
import LiteraryPublishingPage from './pages/LiteraryPublishing.js';
import TechnologyPage from './pages/Technology.js';
import TermsOfUsePage from './pages/TermsOfUse.js';
import PrivacyPolicyPage from './pages/PrivacyPolicy.js';
import ContactPage from './pages/Contact.js';
import AboutPage from './pages/About.js';
import PageNotFound from './pages/PageNotFound.js';

function App() {
  return (
    <Routes>
      <Route path="/" element={<IndexPage />} />
         
      <Route path="/publishing" element={<PublishingPage />} />

      <Route path="/academicpublishing" element={<AcademicPublishingPage />} />

      <Route path="/literarypublishing" element={<LiteraryPublishingPage />} />

      <Route path="/technology" element={<TechnologyPage />} />

      <Route path="/termsofuse" element={<TermsOfUsePage />} />

      <Route path="/privacypolicy" element={<PrivacyPolicyPage />} />

      <Route path="/contact" element={<ContactPage />} />

      <Route path="/about" element={<AboutPage />} />

      <Route path="*" element={<PageNotFound />} />

    </Routes>
  );
}

export default App;
