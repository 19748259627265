import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import PublishingFeatures1 from '../components/features/PublishingFeatures1';
import PublishingFeatures2 from '../components/features/PublishingFeatures2';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'Publishing\u0020\u002D\u0020Academic\u0020and\u0020Literary',
  meta: [
    { property: 'og\u003Aimage', content: '' },
    {
      property: 'og\u003Adescription',
      content:
        'We\u0020provide\u0020comprehensive\u0020services\u0020for\u0020academic\u0020and\u0020literary\u0020publishing.',
    },
    {
      property: 'og\u003Atitle',
      content: 'Publishing\u0020\u002D\u0020Academic\u0020and\u0020Literary',
    },
    {
      name: 'description',
      content:
        'We\u0020provide\u0020comprehensive\u0020services\u0020for\u0020academic\u0020and\u0020literary\u0020publishing.',
    },
  ],
  link: [],
  style: [],
  script: [],
};

export default function Publishing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <PublishingFeatures1 />
      <PublishingFeatures2 />
      <SiteFooter />
    </React.Fragment>
  );
}

