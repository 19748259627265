import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import TechnologyFeatures from '../components/features/TechnologyFeatures';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'Technology in Publishing',
  meta: [
    { property: 'og\u003Aimage', content: 'https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/og-technology.jpg' },
    { property: 'og\u003Adescription', content: 'By blending the latest tools with our deep industry expertise, we bring your creative vision to life in the most efficient and impactful way possible.' },
    { property: 'og\u003Atitle', content: 'Technology in Publishing' },
    { name: 'description', content: 'By blending the latest tools with our deep industry expertise, we bring your creative vision to life in the most efficient and impactful way possible.' },
  ],
  link: [],
  style: [],
  script: [],
};

export default function Technology() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <TechnologyFeatures />
      <SiteFooter />
    </React.Fragment>
  );
}

