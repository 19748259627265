import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import LiteraryPublishingFeatures from '../components/features/LiteraryPublishingFeatures';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'Literary Publishing',
  meta: [
    { property: 'og\u003Aimage', content: 'https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/og-literary-publishing.jpg' },
    { property: 'og\u003Adescription', content: 'From idea to publication, we are your writing partner. Expert editing, proofreading, formatting, consulting, and publishing services.' },
    { property: 'og\u003Atitle', content: 'Literary Publishing' },
    { name: 'description', content: 'From idea to publication, we are your writing partner. Expert editing, proofreading, formatting, consulting, and publishing services.' },
  ],
  link: [],
  style: [],
  script: [],
};

export default function LiteraryPublishing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <LiteraryPublishingFeatures />
      <SiteFooter />
    </React.Fragment>
  );
}

