import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import IndexFeatures from '../components/features/IndexFeatures';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'Learium - Global Publisher',
  meta: [
    { property: 'og\u003Aimage', content: 'https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/og-home-page.jpg' },
    { property: 'og\u003Adescription', content: 'Contact Learium' },
    { property: 'og\u003Atitle', content: 'Learium - Academic and Literary Publisher' },
    { name: 'description', content: 'Contact Learium' },
  ],
  link: [
    {
        rel: 'stylesheet',
        href: 'https://vv-cdn.s3.ap-south-1.amazonaws.com/cookie-notifs/cookies-notification.min.css'
    }
  ],
  style: [],
  script: [
    {
        src: 'https://vv-cdn.s3.ap-south-1.amazonaws.com/cookie-notifs/cookies-notification.min.js'
    },
    {
        src: 'https://vv-cdn.s3.ap-south-1.amazonaws.com/cookie-notifs/cookie-notif.js'
    }
  ],
};

export default function Index() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <IndexFeatures />
      <SiteFooter />
    </React.Fragment>
  );
}

