import React from 'react';

export default function PublishingFeatures2() {
    return (
        <React.Fragment>
            <>
                <section className="py-20">  <div className="container">    <div className="row align-items-center mx-auto">      <div className="col-12 col-md-6 mb-14 mb-md-0">        <div className="mw-md mx-auto">          <h1 className="h2 mb-8">Literary Publishing</h1>          <p className="fs-7 mb-10">We offer comprehensive support for writers and literary enthusiasts. Our services include manuscript editing, proofreading, book formatting, literary consulting, and publishing guidance. We help you transform your literary vision into a polished, publishable work. Our expert team provides tailored solutions to refine your narrative, perfect your manuscript, and navigate the publishing industry.</p>          <a className="btn mb-12 shadow btn-dark" href="/literarypublishing">Learn More</a>      </div>    </div>      <div className="col-12 col-md-6">        <img className="img-fluid" src="https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/literary-publishing.webp" alt="literary publishing" />    </div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

