import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import TermsOfUseFeatures from '../components/features/TermsOfUseFeatures';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'Terms and Conditions',
  meta: [
    { property: 'og\u003Aimage', content: 'https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/og-all.jpg' },
    { property: 'og\u003Adescription', content: 'Global publishing company committed to making knowledge accessible worldwide' },
    { property: 'og\u003Atitle', content: 'Terms and Conditions' },
    { name: 'description', content: 'Global publishing company committed to making knowledge accessible worldwide' },
  ],
  link: [],
  style: [],
  script: [],
};

export default function TermsOfUse() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <TermsOfUseFeatures />
      <SiteFooter />
    </React.Fragment>
  );
}

