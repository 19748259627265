import React from 'react';

export default function PublishingFeatures1() {
    return (
        <React.Fragment>
            <>
                <section className="py-20">  <div className="container">    <div className="row align-items-center mx-auto">      <div className="col-12 col-md-6 mb-14 mb-md-0">        <div className="mw-md mx-auto">          <h1 className="h2 mb-8">Academic Publishing</h1>          <p className="fs-7 mb-10 text-justify">We streamline the academic publishing process for authors with comprehensive support. Our services encompass editing, typesetting, pre-press, production, distribution, and manuscript evaluation. Our dedicated team provides the expertise and resources to help you achieve publishing excellence.</p>          <a className="btn mb-12 shadow btn-dark" href="/academicpublishing">Learn More</a>      </div>    </div>      <div className="col-12 col-md-6">        <img className="img-fluid" src="https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/academic-publishing.webp" alt="academic publishing" />    </div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

