import React from 'react';

export default function PageNotFoundFeatures() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 py-sm-24 py-md-32 overflow-hidden">  <div className="container pb-20">    <div className="text-center">      <img className="mx-auto img-fluid" src="https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/learium-logo.svg" alt="Learium Limited" />  </div></div>  <div className="container position-relative">    <img className="position-absolute top-50 start-50 translate-middle" src="flaro-assets/images/http-codes/gradient3.svg" alt />    <div className="position-relative mw-lg mx-auto px-8 px-md-20 pt-10 pb-14 bg-white text-center rounded-4 shadow-lg">      <img className="d-block mx-auto img-fluid" src="https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/error-404.webp" alt="page not found" />      <p className="mt-10 mb-20 fw-semibold text-secondary-light text-uppercase">The page you are looking for is not found</p>      <h4 className="fs-5 mb-4">Try another page</h4>      <a className="btn p-0 btn-link fw-light d-inline-flex align-items-center" href="/">        <svg width={14} height={12} viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">          <path d="M5.66667 10.6667L1 6.00004M1 6.00004L5.66667 1.33337M1 6.00004L13 6.00004" stroke="#4F46E5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />      </svg>        <span className="ms-2">Go Back to Homepage</span>    </a>  </div></div></section>


            </>
        </React.Fragment>
    );
}

