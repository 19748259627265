import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import AcademicPublishingFeatures from '../components/features/AcademicPublishingFeatures';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'Academic\u0020Publishing',
  meta: [
    { property: 'og\u003Aimage', content: 'https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/og-academic-publishing.jpg' },
    { property: 'og\u003Adescription', content: 'Simplify your academic publishing journey. We offer expert editing, typesetting, pre-press, production, distribution, and manuscript evaluation.' },
    { property: 'og\u003Atitle', content: 'Academic\u0020Publishing' },
    { name: 'description', content: 'Simplify your academic publishing journey. We offer expert editing, typesetting, pre-press, production, distribution, and manuscript evaluation' },
  ],
  link: [],
  style: [],
  script: [],
};

export default function AcademicPublishing() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <AcademicPublishingFeatures />
      <SiteFooter />
    </React.Fragment>
  );
}

