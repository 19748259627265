import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import PageNotFoundFeatures from '../components/features/PageNotFoundFeatures';

const meta = {
  title: 'Page not Found',
  meta: [],
  link: [],
  style: [],
  script: [],
};

export default function PageNotFound() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <PageNotFoundFeatures />
    </React.Fragment>
  );
}

