import React from 'react';

export default function ContactFeatures() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 position-relative overflow-hidden">  <img className="position-absolute top-0 start-0" src="flaro-assets/images/features/elipse.svg" alt />  <div className="container position-relative">    <div className="mx-auto">      <div className="row align-items-center">        <div className="col-12 mb-10">          <h1 className="mb-6 h2">Contact Us</h1>          <div className="mb-4 text-justify">            <h3 className="mb-6 h4">Publishing Enquiries</h3>            <p className="fs-6 text-justify">For publishing enquiries, we encourage authors to reach out via email. Please send your questions, proposals, or manuscript submissions to our team at editor@learium.co.uk. We look forward to assisting you with your publishing journey!</p>        </div>          <div className="mb-4 text-justify">            <h3 className="mb-6 h4">Other Enquiries</h3>            <p className="fs-6 text-justify">For general enquiries, please feel free to reach out to us via email. We’ll be happy to assist you and will respond as promptly as possible. Simply send your questions or requests to contact@learium.co.uk, and our team will get back to you shortly.</p>        </div>      </div>    </div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

