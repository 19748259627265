import React from 'react';

export default function AboutFeatures() {
    return (
        <React.Fragment>
            <>
                <section className="py-12 position-relative overflow-hidden">  <img className="position-absolute top-0 start-0" src="flaro-assets/images/features/elipse.svg" alt />  <div className="container position-relative">    <div className="mx-auto">      <div className="row align-items-center">        <div className="col-12 mb-10">          <div className="mb-4 text-justify">            <p className="fs-6 text-justify">Learium is a global publishing company headquartered in the UK. We provide a full spectrum of publishing services to help authors transform their manuscripts into powerful books. Our expertise covers both academic and literary publishing.</p>            <p className="fs-6 text-justify">We have a robust distribution network that ensures our books reach readers worldwide across various platforms. Our in-house tech team optimizes workflows to streamline the entire production process, from manuscript submission to final distribution.</p>            <p className="fs-6 text-justify">With both in-house and contracted printing capabilities, we provide global print-on-demand services for our authors.</p>        </div>          <div className="mt-10 mb-4 text-justify">            <h3 className="mb-6 h4">Registered in England and Wales</h3>            <p className="fs-6">Learium Limited, 71-75 Shelton Street, Covent Garden, London, WC2H 9JQ</p>            <p className="fs-6">Company No. 15377452</p>        </div>      </div>    </div>  </div></div></section>


            </>
        </React.Fragment>
    );
}

