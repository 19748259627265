import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import SiteHeader from '../components/navigations/SiteHeader';
import AboutFeatures from '../components/features/AboutFeatures';
import SiteFooter from '../components/footers/SiteFooter';

const meta = {
  title: 'About Learium',
  meta: [
    { property: 'og\u003Aimage', content: 'https://learium-cdn.s3.eu-west-2.amazonaws.com/web-v1/og-all.jpg' },
    { property: 'og\u003Adescription', content: 'About Learium' },
    { property: 'og\u003Atitle', content: 'About Learium' },
    { name: 'description', content: 'Learium is a global publisher.' },
  ],
  link: [],
  style: [],
  script: [],
};

export default function About() {
  return (
    <React.Fragment>
      <HelmetProvider>
        <Helmet {...meta}></Helmet>
      </HelmetProvider>
      <SiteHeader />
      <AboutFeatures />
      <SiteFooter />
    </React.Fragment>
  );
}

